import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ChatbotLayout, Chatbot } from '@components';

const StyledMainContainer = styled.main`
  counter-reset: section;
`;

const IframeWrapper = styled.div`
  position: relative;
  width: 60%;
  height: 600px;
  margin: 0 auto;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 47px; // Adjust this value based on the footer's height
    background-color: #0a192f; // Match this with the iframe's background color
    z-index: 1;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 0px); // Increase height to hide the covered part
    border: none;
  }
`;

const IndexPage = ({ location }) => (
  <ChatbotLayout location={location}>
    <StyledMainContainer className="fillHeight">
      <Chatbot/>
    </StyledMainContainer>
    <IframeWrapper>
      <iframe
        src="https://virtualassistantaichatbot-jaylyhjoewg8yfzm2pmffs.streamlit.app/?embedded=True&embed_options=dark_theme"
        title="Chatbot iframe"
        tabIndex="-1"
        allowFullScreen
      ></iframe>
    </IframeWrapper>
  </ChatbotLayout>
);

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default IndexPage;